<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-2">
                <h5 class="mb-0">Macro General</h5>
              </div>
              <div class="col-sm-2">
                <label>Tipo Entidad</label>
                <select
                  class="form-control form-control-sm"
                  v-model="filter.entidad"
                >
                  <option value="">Seleccione...</option>
                  <option value="1">Conductor</option>
                  <option value="2">Vehículos</option>
                </select>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-primary mt-4"
                  v-show="filter.entidad"
                  @click="getIndex()"
                >
                  <i class="fas fa-sync-alt"></i>
                </button>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hse</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Macro General</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <!-- CARDS CONDUCTORES -->
            <div class="row" v-if="filter.entidad == 1">
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div class="small-box bg-frontera">
                  <div class="inner textWhite">
                    <h3>{{ totalCom }}</h3>
                    <p>Conductores</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users textWhite"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div
                  :class="
                    fechaVenci < hoy
                      ? 'small-box bg-gradient-warning'
                      : 'small-box bg-gradient-success'
                  "
                >
                  <div class="inner">
                    <h3>{{ fechaVenci }}</h3>
                    <p>Fecha Vencimiento</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-calendar-alt"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div class="small-box bg-gradient-info">
                  <div class="inner">
                    <h3>{{ falseCom }}</h3>
                    <p>Cantidad Comparendos 'No'</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-times"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div class="small-box bg-gradient-danger">
                  <div class="inner">
                    <h3>{{ trueCom }}</h3>
                    <p>Cantidad Comparendos 'Si'</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-clipboard-check"></i>
                  </div>
                </div>
              </div>
            </div>
            <!--CARDS VEHICULOS -->
            <div class="row" v-else-if="filter.entidad == 2">
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div class="small-box bg-frontera">
                  <div class="inner textWhite">
                    <h3>{{ totalVh }}</h3>
                    <p>Vehículos</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-users textWhite"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div
                  :class="
                    fechaVenci < hoy
                      ? 'small-box bg-gradient-warning'
                      : 'small-box bg-gradient-primary'
                  "
                >
                  <div class="inner">
                    <h3>{{ fechaTarj }}</h3>
                    <p>Fecha Vencimiento Tarjeton</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-id-card"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div
                  :class="
                    fechaVenci < hoy
                      ? 'small-box bg-gradient-warning'
                      : 'small-box bg-gradient-success'
                  "
                >
                  <div class="inner">
                    <h3>{{ fechaVenci }}</h3>
                    <p>Fecha Vencimiento Documental</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-clipboard-check"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-3 col-6 d-flex align-items-stretch flex-column"
              >
                <div class="small-box bg-gradient-warning">
                  <div class="inner">
                    <h3>{{ fechaInspe }}</h3>
                    <p>Fecha Vencimiento Inspección</p>
                  </div>
                  <div class="icon">
                    <i class="fas fa-clipboard-list"></i>
                  </div>
                </div>
              </div>
            </div>
            <!--FILTROS GENERALES CONDUCTORES -->
            <div class="card" v-if="filter.entidad == 1">
              <div class="card-header bg-frontera">
                <h5 class="card-title text-white">
                  <i class="fas fa-filter"></i><b> Filtros Generales</b>
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fas fa-minus"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn btn-tool"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <table
                  id="conduc"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        # Solicitud
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filter.id"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Documento
                        <select
                          class="form-control form-control-sm"
                          v-model="filter.tipo_documento"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_documento in listasForms.tipo_documentos"
                            :key="tipo_documento.numeracion"
                            :value="tipo_documento.numeracion"
                          >
                            {{ tipo_documento.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        N° Documento
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filter.n_documento"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filter.nombre"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="empresa"
                          placeholder="Empresa"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          @input="selectEmpresa()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Fin Pase Campo
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filter.fecha_fin_pase_campo"
                          @input="getIndex()"
                        />
                      </th>
                      <th>
                        Clase Vehículo
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="clase_vehiculo"
                          placeholder="Clase Vehículo"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.clases_vehiculos"
                          @input="selectClaseVehiculo()"
                        ></v-select>
                      </th>
                      <th class="text-center">Comparendo</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filter.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(con, k) in conductores.data">
                      <tr
                        data-widget="expandable-table"
                        aria-expanded="false"
                        :key="k"
                      >
                        <td>{{ con.id }}</td>
                        <td>{{ con.tipoDoc }}</td>
                        <td>{{ con.numero_documento }}</td>
                        <td>{{ con.nombres + " " + con.apellidos }}</td>
                        <td>{{ con.nEmpresa }}</td>
                        <td class="text-center" v-if="con.fecha_fin_pase_campo">
                          {{ con.fecha_fin_pase_campo }}
                        </td>
                        <td class="text-center" v-else>
                          <span class="badge badge-danger"> Sin Asignar</span>
                        </td>
                        <td>{{ con.claseVh }}</td>
                        <td class="text-center">
                          <i
                            v-if="con.comparendos == 1"
                            class="fa fa-times-circle text-danger"
                            data-toggle="tooltip"
                            data-placement="right"
                          ></i>
                          <i
                            v-else
                            class="fa fa-check text-success"
                            data-toggle="tooltip"
                            data-placement="right"
                          ></i>
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              con.estado == 1
                                ? 'bg-warning'
                                : con.estado == 2
                                ? 'bg-success'
                                : con.estado == 4
                                ? 'bg-navy'
                                : con.estado == 3 || con.estado == 5
                                ? 'bg-danger'
                                : 'bg-lime'
                            "
                          >
                            {{ con.Estado }}
                          </span>
                        </td>
                      </tr>
                      <tr class="expandable-body d-none" :key="k + 'a'">
                        <td colspan="9">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-calendar-alt"></i
                                        >{{ con.fecha_insc_runt }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Fecha Inscripción Runt</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3" style="font-size: 13px">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-calendar-alt"></i
                                        >{{ con.fecha_nacimiento }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Fecha Nacimiento</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-building"></i
                                        >{{ con.Empresa }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Empresa</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-info-circle"></i>
                                        {{ con.Activo }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Activo</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-1"></div>
                                  <div class="col-sm-4">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-file-contract"></i
                                        >{{ con.TipoContrato }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Tipo Contrato</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-2">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-user"></i
                                        >{{ con.experiencia_cond }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Exp Conductor</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-5">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-truck-moving"></i
                                        >{{ con.claseVh }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Clase Vehículo</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3"></div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-id-badge"></i
                                        >{{ con.CategoriaLic }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Categoria Licencia</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-4">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-at"></i>{{ con.email }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Email</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="conductores.total">
                  <p>
                    Mostrando del <b>{{ conductores.from }}</b> al
                    <b>{{ conductores.to }}</b> de un total:
                    <b>{{ conductores.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="conductores"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
            <!--FILTROS GENERALES VEHICULOS -->
            <div class="card" v-else-if="filter.entidad == 2">
              <div class="card-header bg-frontera">
                <h5 class="card-title text-white">
                  <i class="fas fa-filter"></i><b> Filtros Generales</b>
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                  >
                    <i class="fas fa-minus"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn btn-tool"
                    @click="getIndex()"
                  >
                    <i class="fas fa-sync"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <table
                  id="conduc"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        # Solicitud
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filter.id"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Placa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filter.placa"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="empresa"
                          placeholder="Empresa"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.empresas"
                          @input="selectEmpresa()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Fin Tarjeton
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filter.fecha_fin_tarjeton"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Sitio
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filter.sitio"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Area
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="area"
                          placeholder="Area"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.areas"
                          @input="selectArea()"
                        ></v-select>
                      </th>
                      <th>
                        Marca
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="marca"
                          placeholder="Marca"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.marcas"
                          @input="selectMarca()"
                        ></v-select>
                      </th>
                      <th>
                        Modelo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filter.modelo"
                          style="font-size: 10px; background-color: #fff"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Clase Vehículo
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="clase_vehiculo"
                          placeholder="Clase Vehículo"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.clases_vehiculos"
                          @input="selectClaseVehiculo()"
                        ></v-select>
                      </th>
                      <th>
                        Tipo Servicio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="tipo_servicio_placa"
                          placeholder="Tipo Servicio"
                          label="descripcion"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.tipos_servicios"
                          @input="selectTipoServicio()"
                        ></v-select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filter.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(vh, k) in vehiculos.data">
                      <tr
                        data-widget="expandable-table"
                        aria-expanded="false"
                        :key="k"
                      >
                        <td>{{ vh.id }}</td>
                        <td>{{ vh.placa }}</td>
                        <td>{{ vh.nEmpresa }}</td>
                        <td class="text-center" v-if="vh.fecha_fin_tarjeton">
                          {{ vh.fecha_fin_tarjeton }}
                        </td>
                        <td class="text-center" v-else>
                          <span class="badge badge-danger">Sin Asignar</span>
                        </td>
                        <td>{{ vh.sitios.nombre }}</td>
                        <td>{{ vh.Area }}</td>
                        <td>{{ vh.Marca }}</td>
                        <td>{{ vh.modelo }}</td>
                        <td>
                          {{ vh.claseVh }}
                        </td>
                        <td>
                          {{ vh.tipoServicio }}
                        </td>
                        <td class="text-center">
                          <span
                            class="badge"
                            :class="
                              vh.estado == 1
                                ? 'bg-warning'
                                : vh.estado == 2
                                ? 'bg-success'
                                : vh.estado == 4
                                ? 'bg-navy'
                                : vh.estado == 3 || vh.estado == 5
                                ? 'bg-danger'
                                : 'bg-lime'
                            "
                          >
                            {{ vh.Estado }}
                          </span>
                        </td>
                      </tr>
                      <tr class="expandable-body d-none" :key="k + 'a'">
                        <td colspan="10">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-calendar-alt"></i
                                        >{{ vh.fecha_ingreso }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Fecha Ingreso</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3" style="font-size: 13px">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-calendar-alt"></i
                                        >{{ vh.fecha_salida }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Fecha Salida</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-building"></i
                                        >{{ vh.Empresa }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Empresa</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-info-circle"></i>
                                        {{ vh.Activo }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Activo</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-1"></div>
                                  <div class="col-sm-4">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-mountain"></i
                                        >{{ vh.Area }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Area</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-2">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-tools"></i
                                        >{{ vh.Operacion }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Operación</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-5">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-truck-moving"></i
                                        >{{ vh.claseVh }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Clase Vehículo</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-3"></div>
                                  <div class="col-sm-3">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-calendar-plus"></i
                                        >{{ vh.fecha_matricula }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Fecha Matricula</span
                                      >
                                    </div>
                                  </div>
                                  <div class="col-sm-1"></div>
                                  <div class="col-sm-2">
                                    <div class="description-block">
                                      <p class="description-header">
                                        <i class="fas fa-at"></i>{{ vh.email }}
                                      </p>
                                      <span
                                        class="description-text text-secondary"
                                        >Email</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="vehiculos.total">
                  <p>
                    Mostrando del <b>{{ vehiculos.from }}</b> al
                    <b>{{ vehiculos.to }}</b> de un total:
                    <b>{{ vehiculos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="vehiculos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import moment from "moment";

export default {
  name: "MacroGeneral",
  components: {
    pagination,
    vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      empresas: [],
      marca: [],
      area: [],
      clase_vehiculo: [],
      tipo_servicio_placa: [],
      empresa: [],
      conductores: {},
      vehiculos: {},
      filter: {
        id: null,
        tipo_documento: null,
        n_documento: null,
        nombre: null,
        estado: null,
        marca_id: null,
        area: null,
        sitio: null,
        clase_vehiculo: null,
        empresa: null,
        entidad: null,
        linea_negocio: null,
      },
      totalCom: 0,
      totalVh: 0,
      trueCom: 0,
      falseCom: 0,
      fechaVenci: null,
      fechaTarj: null,
      fechaInspe: null,
      hoy: moment().format("YYYY-MM-DD"),
      listasForms: {
        estados: [],
        entidades: [],
        tipo_documento: [],
        marcas: [],
        areas: [],
        clases_vehiculos: [],
        empresas: [],
        tipos_servicios: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      this.filter.linea_negocio = this.$store.state.menus[0].linea_negocio;
      axios
        .get("/api/hse/HseMacroGeneral?page=" + page, {
          params: this.filter,
        })
        .then((response) => {
          /* Data Solcitud Conductor */
          if (response.data.solicitudCon != undefined) {
            this.conductores = response.data.solicitudCon;
            this.totalCom = response.data.solicitudCon.total;
            this.trueCom = response.data.compaTotalSi.total;
            this.falseCom = response.data.compaTotalNo.total;
            if (response.data.fechaVenci.data[0] && this.filter.n_documento) {
              /* Fecha Fin Pase */
              if (
                response.data.fechaVenci.data[0].fecha_fin_pase_campo != null
              ) {
                this.fechaVenci =
                  response.data.fechaVenci.data[0].fecha_fin_pase_campo;
              } else {
                /* Fecha Documento */
                this.fechaVenci = response.data.fechaVenci.data[0]
                  .documentos_files[0]
                  ? response.data.fechaVenci.data[0].documentos_files[0]
                      .fecha_vencimiento
                  : "Y-m-d";
              }
            } else {
              this.fechaVenci = "Y-m-d";
            }
            /* Data Solcitud Vehículo */
          } else if (response.data.solicitudVh != undefined) {
            this.vehiculos = response.data.solicitudVh;
            this.totalVh = response.data.solicitudVh.total;
            /*  */
            if (response.data.inspecciones.data[0] && this.filter.placa) {
              if (
                response.data.inspecciones.data[0].vehiculos.tif_inspeccion[0]
              ) {
                this.fechaInspe =
                  response.data.inspecciones.data[0].vehiculos.tif_inspeccion[0].fecha_vencimiento;
              } else {
                this.fechaInspe = "Y-m-d";
              }
            } else {
              this.fechaInspe = "Y-m-d";
            }
            /*  */
            if (response.data.fechaVenci.data[0] && this.filter.placa) {
              /* Fecha Trajeton Vencimiento */
              this.fechaTarj = response.data.fechaVenci.data[0]
                .fecha_fin_tarjeton
                ? response.data.fechaVenci.data[0].fecha_fin_tarjeton
                : "Y-m-d";
              /* Fecha Documento */
              this.fechaVenci = response.data.fechaVenci.data[0]
                .documentos_files[0]
                ? response.data.fechaVenci.data[0].documentos_files[0]
                    .fecha_vencimiento
                : "Y-m-d";
            } else {
              this.fechaVenci = "Y-m-d";
              this.fechaTarj = "Y-m-d";
            }
          }
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/175").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getTipoDoc() {
      axios.get("/api/lista/6").then((response) => {
        this.listasForms.tipo_documentos = response.data;
      });
    },

    getClaseVehiculo() {
      axios.get("/api/lista/163").then((response) => {
        this.listasForms.clases_vehiculos = response.data;
      });
    },

    getTipoServicio() {
      axios.get("/api/lista/181").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    getEmpresas() {
      axios.get("/api/lista/164").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    getMarcas() {
      axios.get("/api/lista/12").then((response) => {
        this.listasForms.marcas = response.data;
      });
    },

    getAreas() {
      axios.get("/api/lista/99").then((response) => {
        this.listasForms.areas = response.data;
      });
    },

    selectMarca() {
      this.filter.marca_id = null;
      if (this.marca) {
        this.filter.marca_id = this.marca.numeracion;
      }
      this.getIndex();
    },

    selectClaseVehiculo() {
      this.filter.clase_vehiculo = null;
      if (this.clase_vehiculo) {
        this.filter.clase_vehiculo = this.clase_vehiculo.numeracion;
      }
      this.getIndex();
    },

    selectTipoServicio() {
      this.filter.tipo_servicio_placa = null;
      if (this.tipo_servicio_placa) {
        this.filter.tipo_servicio_placa = this.tipo_servicio_placa.numeracion;
      }
      this.getIndex();
    },

    selectEmpresa() {
      this.filter.empresa = null;
      if (this.empresa) {
        this.filter.empresa = this.empresa.numeracion;
      }
      this.getIndex();
    },

    selectArea() {
      this.filter.area = null;
      if (this.area) {
        this.filter.area = this.area.numeracion;
      }
      this.getIndex();
    },

    limpiarFilter() {
      this.filter = {
        id: null,
        tipo_documento: null,
        n_documento: null,
        nombre: null,
        estado: null,
        marca_id: null,
        clase_vehiculo: null,
        entidad: null,
      };
    },
  },
  mounted() {
    this.getIndex();
    this.getClaseVehiculo();
    this.getTipoServicio();
    this.getEmpresas();
    this.getEstados();
    this.getTipoDoc();
    this.getMarcas();
    this.getAreas();
  },
};
</script>
<style>
.textWhite {
  color: white;
}
</style>
